import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./header.scss";
import { MdAccountCircle, MdKeyboardArrowDown } from "react-icons/md";
import logo from "../../../assets/images/7_rocks_logo.png";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../reduxStore/userSlice";
// import clearMyTaskData from '../../../reduxStore/taskSlice'
// import axios from '../../../config/axiosinstance';
// import { toast } from 'react-hot-toast';

const Header = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.user.currentUser);

  // const isLoading = useSelector(state => state.user.isLoading)
  // console.log("isLoading :-", isLoading)

  // let service_name = "login";
  // let username = "admin";
  // let password = "Admin@2022";

  // const handleLogin =  async () => {
  //   dispatch(loginStart())
  //   try{
  //       console.log("<<<<<< try block")
  //       const resp = await axios.post("/timesheet-web-api", {service_name, username, password})
  //       console.log("new login resp :-", resp)
  //       dispatch(loginSuccess(resp.data))

  //   }catch (err){
  //     console.log(">>>>>>>>>>>> catch block", err)
  //     toast.error(err.message)
  //     dispatch(loginFailure())
  //   }
  // }

  // console.log("currentUser :-", currentUser)

  const [open, setOpen] = useState(false);

  const handleLogout = async () => {
    setOpen(false);
    dispatch(logout());
    navigate("/login");
    // dispatch(clearMyTaskData())
  };

  return (
    <header>
      <div className="header_container max_container">
        <div className="header_nav">
          <NavLink to="/taskManager">
            <img src={logo} alt="" className="brand_logo" />
          </NavLink>
          {/* <span className='desktop_view'>|</span>
          <NavLink to="/" className="desktop_view">home</NavLink>
          <span className='desktop_view'>|</span>
          <NavLink to="/taskManager" className="desktop_view">task manager</NavLink>
          <span className='desktop_view'>|</span>
          <span className='text-danger desktop_view'>{new Date().toLocaleTimeString()}</span> */}
        </div>
        {currentUser ? (
          <div className="userProfile">
            <div
              className="dropdownToggle"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <img
                src="https://www.w3schools.com/howto/img_avatar.png"
                alt=""
              />
              {/* <MdLockPerson /> */}
              <div className="user_profile_details desktop_view">
                <h4>{currentUser?.User?.name}</h4>
                {/* <h6>Frontend developer</h6> */}
                <h6>{currentUser?.User?.email}</h6>
              </div>
              <MdKeyboardArrowDown
                className={`toggleIcon desktop_view ${
                  open ? "active" : "inactive"
                }`}
              />
            </div>
            <ul className={`dropdownMenu ${open ? "active" : "inactive"}`}>
              <li className="user_profile_details">
                <h4>{currentUser?.User?.name}</h4>
                <h6>{currentUser?.User?.email}</h6>
              </li>
              <li>
                <Link
                  className="dropdownItem"
                  href="#"
                  onClick={() => handleLogout()}
                >
                  logout
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          // <button  className='btn' onClick={() => <Navigate to='/login'/>}> {/*onClick={() => handleLogin()}*/}

          //   &nbsp;
          //   login
          // </button>
          <NavLink to="/login" className="btn border-0 outline-none">
            <MdAccountCircle />
            &nbsp; login
          </NavLink>
        )}
      </div>
    </header>
  );
};

export default Header;
