import React from "react";
import LoaderIcon from "../../../assets/images/Counterrotation.gif";

const Loader = () => {
  return (
    <div className="loader-gif">
      <img src={LoaderIcon} alt="" />
    </div>
  );
};

export default Loader;
