import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./App.scss";
// import HomePage from './pages/homePage/HomePage';
import TaskManager from "./pages/taskManagerPage/TaskManager";
import Header from "./components/common/header/Header";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import MyTask from "./pages/taskManagerPage/myTask/MyTask";
import AssignTask from "./pages/taskManagerPage/assignTask/AssignTask";
import MasterTask from "./pages/taskManagerPage/masterTask/MasterTask";
import DeletedTask from "./pages/taskManagerPage/deletedTask/DeletedTask";
import PendingTask from "./pages/taskManagerPage/pendingTask/PendingTask";
import RequestExtension from "./pages/taskManagerPage/requestExtension/RequestExtension";
import UserTask from "./components/taskManager/UserTask";
import PendingTaskActivity from "./components/taskManager/PendingTaskActivity";
// import AssignTask from './pages/taskManagerPage/assignTask/AssignTask';
// import Footer from './components/common/footer/Footer';

import { Toaster } from "react-hot-toast";

// for drag and drop
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Login from "./pages/loginPage/Login";
import SelfTask from "./pages/taskManagerPage/selfTask/SelfTask.jsx";

const Layout = () => {
  return (
    <div className="app">
      <Header />
      <Outlet />
    </div>
  );
};
const routes = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <Navigate to='login'/>,
  // },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      // {
      //   path: '',
      //   element: <HomePage />
      // },
      {
        path: "",
        element: <Navigate to="taskManager" />,
      },
      {
        path: "taskManager",
        element: <TaskManager />,
        children: [
          {
            path: "",
            element: <Navigate to="my-task" />,
          },
          {
            path: "my-task",
            element: <MyTask />,
          },
          {
            path: "assign-task",
            element: <AssignTask />,
          },
          {
            path: "self-task",
            element: <SelfTask />,
          },
          {
            path: "master-task-board",
            element: <MasterTask />,
            children: [
              {
                path: "user/:id/:searchId?/:isMaster?",
                element: <UserTask />,
              },
            ],
          },
          {
            path: "deleted-task",
            element: <DeletedTask />,
          },
          {
            path: "pending-task",
            element: <PendingTask />,
            children: [
              {
                path: "user/:id/:master?",
                element: <PendingTaskActivity />,
              },
            ],
          },
          {
            path: "request-extension",
            element: <RequestExtension />,
          },
        ],
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

function App() {
  // console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  return (
    <DndProvider backend={HTML5Backend}>
      <Toaster
        toastOptions={{
          style: {
            fontFamily: "Poppins",
            fontWeight: "500",
            borderRadius: "5px",
            color: "white",
            whiteSpace: "nowrap",
          },
          success: {
            style: {
              background: "#597E52",
            },
          },
          error: {
            style: {
              background: "#B31312",
            },
          },
        }}
      />
      <RouterProvider router={routes} />
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<HomePage />}/>
            <Route path="/taskManager" element={<TaskManager />}>
              <Route path="" render={() => 
                {
                  <Redirec to="" />
                }
              }/>
              <Route path="my-task" element={<MyTask />}/>
            </Route>
            <Route path="*" element={<PageNotFound />}/>
          </Route>
        </Routes>
      </BrowserRouter> */}
    </DndProvider>
  );
}

export default App;
