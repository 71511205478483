import React, { useState } from "react";
import HTMLReactParser from "html-react-parser";
import "../../pages/taskManagerPage/taskManager.scss";
import Icon from "../../assets/icons/icon";

const BaseURL = ({ closeURLModal }) => {
  const [selectedURL, setSelectedURL] = useState("");

  const handleURL = (e) => {
    setSelectedURL(e.target.value);
    console.log("Selected URL: ", e.target.value);
  };

  const handleSubmitURL = async () => {
    console.log("Submitted URL: ", selectedURL);
    await localStorage.setItem("BaseUrlTaskManager", selectedURL);
    await closeURLModal();
    setTimeout(() => {
      window.location.reload();
    }, 100);
    // Handle the submit action here
  };

  return (
    <div className="custom_modal restore_modal">
      <div className="custom_modal_backdrop"></div>
      <div className="custom_modal_container">
        <div className="custom_modal_header">
          <h4>CHANGE BASE URL</h4>
        </div>
        <div className="custom_modal_body">
          <form className="mt-4">
            <div className="custom_input_field">
              <label>Choose URL</label>
              <select
                className="input_field"
                name="reward_types"
                value={selectedURL}
                onChange={handleURL}
              >
                <option value="">Choose Option</option>
                <option value="https://staging.sevenrocks.in/">Staging</option>
                <option value="https://erp.sevenrocks.in/">Live</option>
              </select>
              <i className="icon">{HTMLReactParser(Icon.select_input_icon)}</i>
            </div>
            <button
              onClick={handleSubmitURL}
              className="submit_btns"
              type="button"
            >
              SUBMIT
            </button>
            <button
              onClick={() => closeURLModal(false)}
              className="submit_btn_cancel"
              type="button"
            >
              CANCEL
            </button>
          </form>
        </div>
        <div className="custom_modal_footer"></div>
      </div>
    </div>
  );
};

export default BaseURL;
