import axios from "axios";

let url = localStorage.getItem("BaseUrlTaskManager");

const instance = axios.create({
  // baseURL: 'https://master.sevenrocks.in/timesheet-web-api'
  // baseURL: "https://test-live-300923.sevenrocks.in/",
  // baseURL: "https://erp.sevenrocks.in/",
  baseURL: url ? url : "https://erp.sevenrocks.in/",
});

export default instance;
// console.log(object)
// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
